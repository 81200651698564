import { useStaticQuery, graphql } from 'gatsby';

export default function allNodeContactQuery() {
  const data = useStaticQuery(graphql`
    query NodeContact {
      offices: allNodeOffice(sort: { order: ASC, fields: field_weight }) {
        edges {
          node {
            id
            title
            langcode
            body {
              processed
            }
            phone: field_phone
            field_weight
            relationships {
              image: field_image {
                name: filename

                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
        }
      }
      form: webformWebform(drupal_internal__id: { eq: "contact" }) {
        webformId: drupal_internal__id
        title
        description
        elements
        settings {
          pageSubmitPath: page_submit_path
          pageConfirmPath: page_confirm_path
          submissionLabel: submission_label
          confirmationMessage: confirmation_message
        }
      }
      formEs: webformWebform(
        drupal_internal__id: { eq: "_trabajemos_juntos_" }
      ) {
        webformId: drupal_internal__id
        title
        description
        elements
        settings {
          pageSubmitPath: page_submit_path
          pageConfirmPath: page_confirm_path
          submissionLabel: submission_label
          confirmationMessage: confirmation_message
        }
      }
      clients: allNodeClients(
        filter: { field_contact: { eq: true } }
        sort: { order: DESC, fields: field_weight }
      ) {
        edges {
          node {
            id
            field_contact
            relationships {
              image: field_image {
                name: filename

                attributes: field_media_image {
                  title
                  alt
                }
              }
              countries: field_countries {
                country: name
                countryCode: field_country_code
                tid: drupal_internal__tid
                langcode
              }
            }
          }
        }
      }
    }
  `);

  return data;
}
